import { render, staticRenderFns } from "./gift-cards.vue?vue&type=template&id=02a60e99&scoped=true"
import script from "./gift-cards.vue?vue&type=script&lang=js"
export * from "./gift-cards.vue?vue&type=script&lang=js"
import style0 from "./gift-cards.vue?vue&type=style&index=0&id=02a60e99&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a60e99",
  null
  
)

export default component.exports